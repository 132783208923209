@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  src: url("RobotoMono.7b49cb08.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
  margin: 0;
  padding: 0;
  font: 22px / 30px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}

[data-selected="true"] {
  background-color: #23c80fcc;
}

.hidden {
  display: none;
}

main {
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

input, textarea {
  max-width: 100%;
  font: 18px / 1.5 Roboto Mono;
}

input {
  min-height: 20px;
}

textarea {
  min-height: 80px;
}

button {
  max-width: 200px;
  max-height: 60px;
  border-radius: 10px;
  padding: 8px;
  font-size: 20px;
}

.control {
  padding-left: 20px;
  padding-right: 20px;
}

.control.group {
  margin-left: auto;
  margin-right: auto;
}

.control.add-condition {
  max-width: 100px;
  min-width: 50px;
}

.container.condition {
  border: 2px solid #000;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 25px;
  padding: 5px;
}

.container.condition textarea {
  width: 100%;
  max-width: 750px;
  min-height: 100px;
}

.container.condition > * {
  margin-top: 2px;
  margin-bottom: 2px;
}

.control.close {
  float: right;
}

.match-option.custom {
  max-width: 600px;
  width: 600px;
}

.container.title {
  width: 100%;
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.container.title input {
  width: 100%;
}

textarea.output {
  min-width: 700px;
  min-height: 500px;
  margin: 10px auto;
}

.group.control.build-buttons {
  justify-content: center;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.control.center {
  margin-left: auto;
  margin-right: auto;
}

/*# sourceMappingURL=index.1ab4c5ed.css.map */
