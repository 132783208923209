@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url("/src/fonts/RobotoMono.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html,
body {
  font: 22px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;

  padding: 0;
  margin: 0;

  line-height: 30px;
}

*[data-selected="true"] {
  background-color: rgba(35, 200, 15, 0.8);
}

.hidden {
  display: none;
}

main {
  display: flex;
  justify-content: center;
  gap: 3px;

  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  max-width: 800px;
}

input,
textarea {
  font: 18px/1.5 "Roboto Mono";
  max-width: 100%;
}

input {
  min-height: 20px;
}

textarea {
  min-height: 80px;
}

button {
  padding: 8px;
  border-radius: 10px;
  font-size: 20px;
  max-width: 200px;
  max-height: 60px;
}

.control {
  padding-left: 20px;
  padding-right: 20px;
}

.control.group {
  margin-left: auto;
  margin-right: auto;
}

.control.add-condition {
  max-width: 100px;
  min-width: 50px;
}

.container.condition {
  margin-top: 5px;
  margin-bottom: 5px;

  margin-left: 25px;

  padding: 5px;
  border: 2px solid black;
}

.container.condition textarea {
  width: 100%;
  max-width: 750px;
  min-height: 100px;
}

.container.condition > * {
  margin-top: 2px;
  margin-bottom: 2px;
}

.control.close {
  float: right;
}

.match-option.custom {
  max-width: 600px;
  width: 600px;
}

.container.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.container.title input {
  width: 100%;
}

textarea.output {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 700px;
  min-height: 500px;
}

.group.control.build-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;

  margin-left: auto;
  margin-right: auto;
}

.control.center {
  margin-left: auto;
  margin-right: auto;
}

